// ЗАКАЖИТЕ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ
function submitEmailC(params) {
  let str = window.atob("aHR0cHM6Ly9vb2NjbWFpbC5ydS9zZW5kP3Rva2VuPXZqOTgzNGhnMmhmNzg5aDIzNGZoNHVmaDM3OGZoMw==");

  fetch(`${str}`, {
    credentials: 'same-origin',
    mode:'no-cors',
    method: 'POST',

    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
    },

    body: `company='ТРАНС ВЕКТОР'&name=${params.name}&phone=${params.phone}`
  })
  .catch(error => console.log(error))
}

function validaionFormFooterC(e, form) {
  e.preventDefault();

  let name = form[0].value;
  let phone = form[1].value;

  console.log(name);
  console.log(phone);

  submitEmailC({ name: name, phone: phone});

  form[0].value = "";
  form[1].value = "";

  alert( "Успешно отправлено!" );
}

// modal form
let modalFormFooterC = document.querySelector('#footer-form');
modalFormFooterC.addEventListener('submit', e => validaionFormFooterC(e, modalFormFooterC));

